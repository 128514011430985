import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./i18n";
import "./vee-validate";
import moment from "moment";
import pptxgen from "pptxgenjs";
const { RayPlugin } = require("vue-ray/vue2");
Vue.use(RayPlugin, { interceptErrors: true, host: "127.0.0.1", port: 23517 });
import { ValidationObserver } from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
import { ValidationProvider } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
import baseRadio from "@/components/input/BaseRadio";
Vue.component("base-radio", baseRadio);
import BaseValArea from "@/components/input/BaseValArea";
Vue.component("base-val-area", BaseValArea);
import BaseValInput from "@/components/input/BaseValInput";
Vue.component("BaseValInput", BaseValInput);
import StatementText from "@/components/statementText";
Vue.component("StatementText", StatementText);
window.EventBus = new Vue();
Vue.config.productionTip = false;
window.pptxgen = pptxgen;
window.moment = moment;
Vue.filter("myDate", function (created) {
  return moment(created).format("DD-MM-YYYY");
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
