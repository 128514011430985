import * as API from "@/services/API";

export default {
  getMain(Id) {
    return API.apiClient.get(`/statute/${Id}`);
  },
  getMains() {
    return API.apiClient.get(`/statute/`);
  },
  postMain(payload) {
    return API.apiClient.post(`/statute/`, payload);
  },
  updateMain(Id, payload) {
    return API.apiClient.put(`/statute/${Id}`, payload);
  },
  deleteMain(Id) {
    return API.apiClient.delete(`/statute/${Id}`);
  },
  showModulesStatute(Id) {
    return API.apiClient.get(`/showModulesStatute/${Id}`);
  },
  updateModulesStatute(Id, payload) {
    return API.apiClient.post(`/updateModulesStatute/${Id}`, payload);
  },
  showAdjustA(Id) {
    return API.apiClient.get(`/showAdjustA/${Id}`);
  },
  updateAdjustA(Id, payload) {
    return API.apiClient.post(`/updateAdjustA/${Id}`, payload);
  },
  showAdjustB(Id) {
    return API.apiClient.get(`/showAdjustB/${Id}`);
  },
  updateAdjustB(Id, payload) {
    return API.apiClient.post(`/updateAdjustB/${Id}`, payload);
  },
  showAdjustAll(Id) {
    return API.apiClient.get(`/showAdjustAll/${Id}`);
  },
  getMembers(famId) {
    return API.apiClient.get(`/statuteMembers/${famId}`);
  },
  getMember(Id) {
    return API.apiClient.get(`/statuteMember/${Id}`);
  },
  postMember(payload) {
    return API.apiClient.post(`/statuteMember/`, payload);
  },
  updateMember(Id, payload) {
    return API.apiClient.put(`/statuteMember/${Id}`, payload);
  },
  deleteMember(Id) {
    return API.apiClient.delete(`/statuteMember/${Id}`);
  },
  showStatusStatute(Id) {
    return API.apiClient.get(`/showStatusStatute/${Id}`);
  },
  getAllResults(Id) {
    return API.apiClient.get(`/indexStatuteReports/${Id}`);
  },
};
