import * as API from "@/services/API";

export default {
  getMeeting(Id) {
    return API.apiClient.get(`/meetingStatute/${Id}`);
  },
  getMeetings(Id) {
    return API.apiClient.get(`/indexMeeting/${Id}`);
  },
  postMeeting(payload) {
    return API.apiClient.post(`/meetingStatute/`, payload);
  },
  updateMeeting(Id, payload) {
    return API.apiClient.put(`/meetingStatute/${Id}`, payload);
  },
  deleteMeeting(Id) {
    return API.apiClient.delete(`/meetingStatute/${Id}`);
  },
  updateActiveMeeting(Id, payload) {
    return API.apiClient.post(`/meetingStatuteUpdateActive/${Id}`, payload);
  },
  sendOneInvitation(payload) {
    return API.apiClient.post(`/invStatuteMailOne/`, payload);
  },
  sendMoreInvitation(payload) {
    return API.apiClient.post(`/invStatuteMailMore/`, payload);
  },
  getSendMailStatute(payload) {
    return API.apiClient.post(`/indexStatuteMails/`, payload);
  },
};
