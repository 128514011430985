import auth from "@/middleware/auth";

const statuteIndex = () =>
  import(/* webpackChunkName: "statuteI" */ "../views/Statute/index");

const statuteView = () =>
  import(/* webpackChunkName: "statuteV" */ "../views/Statute/view");

const statuteEdit = () =>
  import(/* webpackChunkName: "statuteE" */ "../views/Statute/edit");

const statuteCreate = () =>
  import(/* webpackChunkName: "statuteC" */ "../views/Statute/create");

const statuteIndexFam = () =>
  import(/* webpackChunkName: "statuteIndexFam" */ "../views/Statute/indexFam");

const statuteMeetingCreate = () =>
  import(
    /* webpackChunkName: "statuteMeetingCreate" */ "../views/Statute/meetings/create"
  );

const statuteMeetingEdit = () =>
  import(
    /* webpackChunkName: "statuteMeetingEdit" */ "../views/Statute/meetings/edit"
  );

const statuteMeetingEmail = () =>
  import(
    /* webpackChunkName: "statuteMeetingEmail" */ "../views/Statute/meetings/email"
  );
const statuteReportIndex = () =>
  import(
    /* webpackChunkName: "statuteReportIndex" */ "../views/Statute/reports/index"
  );

const statuteModules = () =>
  import(
    /* webpackChunkName: "statuteModules" */ "../views/Statute/Modules/index"
  );

const statuteModuleAdjustA = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustA" */ "../views/Statute/Modules/module-a"
  );

const statuteModuleAdjustB = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustB" */ "../views/Statute/Modules/module-b"
  );

const statuteModuleAdjustC = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustC" */ "../views/Statute/Modules/module-c"
  );
const statuteModuleAdjustD = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustD" */ "../views/Statute/Modules/module-d"
  );
const statuteModuleAdjustE = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustE" */ "../views/Statute/Modules/module-e"
  );

const statuteModuleAdjustF = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustF" */ "../views/Statute/Modules/module-f"
  );
const statuteModuleAdjustG = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustG" */ "../views/Statute/Modules/module-g"
  );
const statuteModuleAdjustH = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustH" */ "../views/Statute/Modules/module-h"
  );
const statuteModuleAdjustI = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustI" */ "../views/Statute/Modules/module-i"
  );
const statuteModuleAdjustJ = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustJ" */ "../views/Statute/Modules/module-j"
  );
const statuteModuleAdjustK = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustK" */ "../views/Statute/Modules/module-k"
  );
const statuteModuleAdjustL = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustL" */ "../views/Statute/Modules/module-l"
  );
const statuteModuleAdjustM = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustM" */ "../views/Statute/Modules/module-m"
  );
const statuteModuleAdjustN = () =>
  import(
    /* webpackChunkName: "statuteModuleAdjustN" */ "../views/Statute/Modules/module-n"
  );

const statuteMemberIndex = () =>
  import(
    /* webpackChunkName: "statuteMemberI" */ "@/views/Statute/member/index"
  );

const statuteMemberCreate = () =>
  import(
    /* webpackChunkName: "statuteMemberC" */ "@/views/Statute/member/create"
  );

const statuteMemberEdit = () =>
  import(
    /* webpackChunkName: "statuteMemberI" */ "@/views/Statute/member/edit"
  );
const statuteMemberView = () =>
  import(
    /* webpackChunkName: "statuteMemberC" */ "@/views/Statute/member/view"
  );
const statuteInvitation = () =>
  import(
    /* webpackChunkName: "statuteInvitation" */ "@/views/Statute/member/invitation"
  );
const statuteStatus = () =>
  import(/* webpackChunkName: "statuteStatus" */ "@/views/Statute/status");

const statuteDefIndex = () =>
  import(/* webpackChunkName: "statuteDefIndex" */ "@/views/Statute/def/index");

const statuteDefA = () =>
  import(
    /* webpackChunkName: "statuteDefA" */ "@/views/Statute/def/statuteDefA"
  );

const statuteDefB = () =>
  import(
    /* webpackChunkName: "statuteDefB" */ "@/views/Statute/def/statuteDefB"
  );

const statuteDefC = () =>
  import(
    /* webpackChunkName: "statuteDefC" */ "@/views/Statute/def/statuteDefC"
  );

const statuteDefD = () =>
  import(
    /* webpackChunkName: "statuteDefD" */ "@/views/Statute/def/statuteDefD"
  );

const statuteDefE = () =>
  import(
    /* webpackChunkName: "statuteDefE" */ "@/views/Statute/def/statuteDefE"
  );

const statuteDefF = () =>
  import(
    /* webpackChunkName: "statuteDefF" */ "@/views/Statute/def/statuteDefF"
  );

const statuteDefG = () =>
  import(
    /* webpackChunkName: "statuteDefG" */ "@/views/Statute/def/statuteDefG"
  );

const statuteDefH = () =>
  import(
    /* webpackChunkName: "statuteDefH" */ "@/views/Statute/def/statuteDefH"
  );

const statuteDefI = () =>
  import(
    /* webpackChunkName: "statuteDefI" */ "@/views/Statute/def/statuteDefI"
  );

const statuteDefJ = () =>
  import(
    /* webpackChunkName: "statuteDefJ" */ "@/views/Statute/def/statuteDefJ"
  );

const statuteDefK = () =>
  import(
    /* webpackChunkName: "statuteDefK" */ "@/views/Statute/def/statuteDefK"
  );

const statuteDefL = () =>
  import(
    /* webpackChunkName: "statuteDefL" */ "@/views/Statute/def/statuteDefL"
  );

const statuteDefM = () =>
  import(
    /* webpackChunkName: "statuteDefM" */ "@/views/Statute/def/statuteDefM"
  );
const statuteDefN = () =>
  import(
    /* webpackChunkName: "statuteDefN" */ "@/views/Statute/def/statuteDefN"
  );

const routes = [
  {
    path: "/statute",
    name: "statuteIndex",
    meta: { middleware: [auth] },
    component: statuteIndex,
  },
  {
    path: "/statute-view/:id",
    name: "statuteView",
    meta: { middleware: [auth] },
    component: statuteView,
  },
  {
    path: "/statute-edit/:id",
    name: "statuteEdit",
    meta: { middleware: [auth] },
    component: statuteEdit,
  },
  {
    path: "/statute-create",
    name: "statuteCreate",
    meta: { middleware: [auth] },
    component: statuteCreate,
  },
  {
    path: "/statute-family/:famId",
    name: "statuteIndexFam",
    meta: { middleware: [auth] },
    component: statuteIndexFam,
  },
  {
    path: "/statute-meeting-create/:famId",
    name: "statuteMeetingCreate",
    meta: { middleware: [auth] },
    component: statuteMeetingCreate,
  },
  {
    path: "/statute-meeting-edit/:famId/:meetId",
    name: "statuteMeetingEdit",
    meta: { middleware: [auth] },
    component: statuteMeetingEdit,
  },
  {
    path: "/statute-meeting-email/:famId/:meetId",
    name: "statuteMeetingEmail",
    meta: { middleware: [auth] },
    // props(route) {
    //   return {
    //     famId: parseInt(route.params.famId),
    //     meetId: parseInt(route.params.meetId),
    //   };
    // },
    component: statuteMeetingEmail,
  },
  {
    path: "/statute-modules/:famId",
    name: "statuteModules",
    meta: { middleware: [auth] },
    component: statuteModules,
  },
  {
    path: "/statute-reports/:famId",
    name: "statuteReports",
    meta: { middleware: [auth] },
    component: statuteReportIndex,
  },
  {
    path: "/statute-module-a-adjust/:famId",
    name: "statuteModuleAdjustA",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustA,
  },
  {
    path: "/statute-module-b-adjust/:famId",
    name: "statuteModuleAdjustB",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustB,
  },
  {
    path: "/statute-module-c-adjust/:famId",
    name: "statuteModuleAdjustC",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustC,
  },
  {
    path: "/statute-module-d-adjust/:famId",
    name: "statuteModuleAdjustD",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustD,
  },
  {
    path: "/statute-module-e-adjust/:famId",
    name: "statuteModuleAdjustE",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustE,
  },
  {
    path: "/statute-module-f-adjust/:famId",
    name: "statuteModuleAdjustF",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustF,
  },
  {
    path: "/statute-module-g-adjust/:famId",
    name: "statuteModuleAdjustG",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustG,
  },
  {
    path: "/statute-module-h-adjust/:famId",
    name: "statuteModuleAdjustH",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustH,
  },
  {
    path: "/statute-module-i-adjust/:famId",
    name: "statuteModuleAdjustI",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustI,
  },
  {
    path: "/statute-module-j-adjust/:famId",
    name: "statuteModuleAdjustJ",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustJ,
  },
  {
    path: "/statute-module-k-adjust/:famId",
    name: "statuteModuleAdjustK",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustK,
  },
  {
    path: "/statute-module-l-adjust/:famId",
    name: "statuteModuleAdjustL",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustL,
  },
  {
    path: "/statute-module-m-adjust/:famId",
    name: "statuteModuleAdjustM",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustM,
  },
  {
    path: "/statute-module-n-adjust/:famId",
    name: "statuteModuleAdjustN",
    meta: { middleware: [auth] },
    component: statuteModuleAdjustN,
  },
  {
    path: "/statute-member-index/:famId",
    name: "statuteMemberIndex",
    meta: { middleware: [auth] },
    component: statuteMemberIndex,
  },
  {
    path: "/statute-member-create/:famId",
    name: "statuteMemberCreate",
    meta: { middleware: [auth] },
    component: statuteMemberCreate,
  },
  {
    path: "/statute-member-view/:famId/:id",
    name: "statuteMemberView",
    meta: { middleware: [auth] },
    component: statuteMemberView,
  },
  {
    path: "/statute-member-edit/:famId/:id",
    name: "statuteMemberEdit",
    meta: { middleware: [auth] },
    component: statuteMemberEdit,
  },
  {
    path: "/statute-invitation/:famId",
    name: "statuteInvitation",
    meta: { middleware: [auth] },
    component: statuteInvitation,
  },
  {
    path: "/statute-status/:famId",
    name: "statuteStatus",
    meta: { middleware: [auth] },
    component: statuteStatus,
  },
  {
    path: "/statute-definitive/:famId",
    name: "statuteDefIndex",
    meta: { middleware: [auth] },
    component: statuteDefIndex,
  },
  {
    path: "/statute-def-a/:famId",
    name: "statuteDefA",
    meta: { middleware: [auth] },
    component: statuteDefA,
  },
  {
    path: "/statute-def-b/:famId",
    name: "statuteDefB",
    meta: { middleware: [auth] },
    component: statuteDefB,
  },
  {
    path: "/statute-def-c/:famId",
    name: "statuteDefC",
    meta: { middleware: [auth] },
    component: statuteDefC,
  },
  {
    path: "/statute-def-d/:famId",
    name: "statuteDefD",
    meta: { middleware: [auth] },
    component: statuteDefD,
  },
  {
    path: "/statute-def-e/:famId",
    name: "statuteDefE",
    meta: { middleware: [auth] },
    component: statuteDefE,
  },
  {
    path: "/statute-def-f/:famId",
    name: "statuteDefF",
    meta: { middleware: [auth] },
    component: statuteDefF,
  },
  {
    path: "/statute-def-g/:famId",
    name: "statuteDefG",
    meta: { middleware: [auth] },
    component: statuteDefG,
  },
  {
    path: "/statute-def-h/:famId",
    name: "statuteDefH",
    meta: { middleware: [auth] },
    component: statuteDefH,
  },
  {
    path: "/statute-def-i/:famId",
    name: "statuteDefI",
    meta: { middleware: [auth] },
    component: statuteDefI,
  },
  {
    path: "/statute-def-j/:famId",
    name: "statuteDefJ",
    meta: { middleware: [auth] },
    component: statuteDefJ,
  },
  {
    path: "/statute-def-k/:famId",
    name: "statuteDefK",
    meta: { middleware: [auth] },
    component: statuteDefK,
  },
  {
    path: "/statute-def-l/:famId",
    name: "statuteDefL",
    meta: { middleware: [auth] },
    component: statuteDefL,
  },
  {
    path: "/statute-def-m/:famId",
    name: "statuteDefM",
    meta: { middleware: [auth] },
    component: statuteDefM,
  },
  {
    path: "/statute-def-n/:famId",
    name: "statuteDefN",
    meta: { middleware: [auth] },
    component: statuteDefN,
  },
];
export default routes;
