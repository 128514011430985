import { getError } from "@/utils/helpers";
import StatuteMeetingService from "@/services/statute/StatuteMeetingService";

export const namespaced = true;

export const state = {
  meetingSingle: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_MEETING(state, meetingSingle) {
    state.meetingSingle = meetingSingle;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getMeeting({ commit }, famId) {
    commit("SET_LOADING", true);

    StatuteMeetingService.getMeeting(famId)
      .then((response) => {
        commit("SET_MEETING", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_MEETING", []);
  },
};
export const getters = {
  meetingSingle: (state) => {
    return state.meetingSingle;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
