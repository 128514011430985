import * as API from "@/services/API";

export default {
  getResultA(Id) {
    return API.apiClient.get(`/statuteResultA/${Id}`);
  },
  getResultB(Id) {
    return API.apiClient.get(`/statuteResultB/${Id}`);
  },
  getResultC(Id) {
    return API.apiClient.get(`/statuteResultC/${Id}`);
  },
  getResultD(Id) {
    return API.apiClient.get(`/statuteResultD/${Id}`);
  },
  getResultE(Id) {
    return API.apiClient.get(`/statuteResultE/${Id}`);
  },
  getResultF(Id) {
    return API.apiClient.get(`/statuteResultF/${Id}`);
  },
  getResultG(Id) {
    return API.apiClient.get(`/statuteResultG/${Id}`);
  },
  getResultH(Id) {
    return API.apiClient.get(`/statuteResultH/${Id}`);
  },
  getResultI(Id) {
    return API.apiClient.get(`/statuteResultI/${Id}`);
  },
  getResultJ(Id) {
    return API.apiClient.get(`/statuteResultJ/${Id}`);
  },
  getResultK(Id) {
    return API.apiClient.get(`/statuteResultK/${Id}`);
  },
  getResultL(Id) {
    return API.apiClient.get(`/statuteResultL/${Id}`);
  },
  getResultM(Id) {
    return API.apiClient.get(`/statuteResultM/${Id}`);
  },
  getResultN(Id) {
    return API.apiClient.get(`/statuteResultN/${Id}`);
  },
};
