import { getError } from "@/utils/helpers";
import StatuteResultService from "@/services/statute/StatuteResultService";

export const namespaced = true;

export const state = {
  h_results: [],
  h_loading: false,
  error: null,
};

export const mutations = {
  SET_RESULT(state, h_results) {
    state.h_results = h_results;
  },
  SET_LOADING(state, loading) {
    state.h_loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getResult({ commit }, famId) {
    commit("SET_LOADING", true);

    StatuteResultService.getResultH(famId)
      .then((response) => {
        commit("SET_RESULT", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_RESULT", []);
  },
};
export const getters = {
  h_results: (state) => {
    return state.h_results;
  },
  h_loading: (state) => {
    return state.h_loading;
  },
  error: (state) => {
    return state.error;
  },
};
